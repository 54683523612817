<template>
    <div>
      <div class="bg-gray-200 p-1 border-b mb-2">
        <template>
          <div class="w-full">
            <div class="grid grid-cols-1 grid-cols-6 gap-1">
              <Superselect
                title="Country"
                v-model="filters.country.value"
                :multiple="false"
                label="label"
                track-by="code"
                :options="countries"
                :loading="$apollo.queries.countries.loading"
              ></Superselect>
              <Superselect
                title="Device"
                v-model="filters.device"
                :multiple="false"
                :options="options.devices"
              ></Superselect>
              <Superselect
                title="Redirect"
                :multiple="false"
                v-model="filters.redirect.value"
                :options="options.redirect"
                placeholder="Select Redirect"
                @select="redirectDropdownChange"
                track-by="id"
                label="label"
                :query="queries.REDIRECT_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :queryResultMap="{
                  id: 'id',
                  label: 'label',
                  relationId:'campaign',
                  relationLabel:'campaign'
                }"
              ></Superselect>
              <Superselect
                title="Campaign"
                v-model="filters.campaign.value"
                :options="options.campaign"
                :multiple="false"
                placeholder="Select Campaign"
                track-by="id"
                label="label"
                :query="queries.CAMPAIGN_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :queryResultMap="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
              <Superselect
                title="Offer"
                v-model="filters.offer.value"
                :options="options.offer"
                placeholder="Select Offer"
                :options-limit="100"
                track-by="id"
                label="label"
                :query="queries.OFFER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 100
                }"
              ></Superselect>
              <Superselect
                title="Vertical"
                v-model="filters.verticals.value"
                :options="verticalOptions"
                placeholder="Vertical"
              ></Superselect>
              <YButton
                v-if="filters.country.value"
                color="blue"
                @click.prevent="openOptimizeModal()"
                >Run Optimization</YButton
              >
              <YButton
                color="blue"
                @click.prevent="toggleQualifiedRedirects()"
                >{{showButtonText}}</YButton
              >
            </div>
          </div>
        </template>
      </div>
      <div class="bg-gray-100 p-1 border-b mb-2">
        Notes:
        <EditableValue
            v-if="algorithmDescription"
            type="contentEditable"
            :mutation="mutations.UPDATE_ALGORITHM_MUTATION"
            :variables="{ input: { id: 11, description: '{value}' } }"
            :value="algorithmDescription"
        />
        <br>
        Last Pulled:  {{ this.lastUpdatedAt }}
      </div>
      <!--Show qualifed redirects-->
      <div 
        v-if="showQualifiedRedirects == true" 
        class="bg-gray-100 p-1 border-b mb-2 overflow-y-scroll"
        style="height: 15rem"
      >
    
        <YTable
          v-if="qualifiedRedirectsV11"
          :data="qualifiedRedirectsV11.data || []"
          :loading="$apolloData.queries.qualifiedRedirectsV11.loading"
        >
          <template slot="header-row">
            <th>Redirect</th>
            <th>Campaign</th>
            <th>Geo</th>
            <th>Revenue</th>
          </template>
          <template slot="row" slot-scope="props">
            <td> 
              <template>
                <div class="flex" :class="'flex-row'">
                    <a
                      href="#"
                      @click.prevent="updateFilters(props.rowData.redirect,props.rowData.campaign)"
                      class="link x-action-icon"
                    >
                    <Icon name="target" class="w-4 h-4" />
                  </a>
  
                    <div>
                      <router-link
                        :to="{
                          name: 'Redirect',
                          params: { id: props.rowData.redirect.id }
                        }"
                        class="link-wrap"
                      >
                        <span class="font-semibold">{{ props.rowData.redirect.id }}</span>
                        ({{ props.rowData.redirect.user.label }})
                      </router-link>
                    </div>
                </div>
              </template>
            </td>
            <td>
              <template>
                <router-link
                  :to="{
                    name: 'Campaign',
                    params: { id: props.rowData.campaign.id }
                  }"
                  class="link-wrap"
                >
                  {{ props.rowData.campaign.label }}
                </router-link>
              </template>
            </td>
            <td>{{ props.rowData.countryCode }}</td>
            <td>${{props.rowData.revenue ?? 0}}</td>
          </template>
        </YTable>
      </div>
      <div class="w-100 h-50">  
        <YTable
          v-if="algorithmsDataV11"
          deletedAtField="isCapped"
          deletedAtClass="text-red-500 font-medium"
          :data="algorithmsDataV11 || []"
          :loading="$apolloData.queries.algorithmsDataV11.loading"
          :empty-text="`Not qualified`"
        >
          <template slot="header-row">
            <th>Offer</th>
            <th>Geo</th>
            <th v-if="filters.redirect?.value !== null ">
              Redirect
            </th>
            <th v-if="filters.redirect?.value !== null">
              Campaign
            </th>
            <th>Rpm</th>
            <th>Revenue</th>
            <th>Weight</th>
            <th>Min</th>
            <th>Max</th>
            <th>Expiry Date</th>
            <th>Fallback</th>
            <!--<th>Actions</th>-->
          </template>
          <template slot="row" slot-scope="props">
            <td>
              <div class="flex flex-row justify-between">
                <template v-if="props.rowData.offer">
                  <div
                    class="flex "
                    :class="filters.level == 'All' ? 'w-44 2xl:w-auto' : 'w-full'"
                  >
                    <router-link
                      :to="{
                        name: 'Offer',
                        params: { id: props.rowData.offer.id, action: 'edit' }
                      }"
                      :class="
                        props.rowData.isCapped
                          ? 'text-red-600 hover:text-red-700 font-medium'
                          : 'link-wrap'
                      "
                    >
                      {{ props.rowData.offer.label }}
                    </router-link>
                  </div>
                </template>
  
                <div
                  class="flex  ml-2 
                  "
                  :class="
                    filters.level == 'All'
                      ? 'w-24 flex-col justify-center 2xl:flex-row 2xl:w-auto'
                      : 'flex-row justify-end'
                  "
                >
                  <div v-for="tag in props.rowData.offer.tags" :key="tag.id">
                    <Tag
                      v-if="tag.type === 'vertical'"
                      :key="tag.id"
                      :color="tag.color"
                      class="mr-3 mb-1 2xl:mb-0"
                      v-tooltip="`Vertical`"
                      >{{ tag.name }}
                    </Tag>
                  </div>
  
                  <div>
                    <Tag
                      v-if="props.rowData.key.mobile_desktop == 'Desktop'"
                      color="blue"
                      >{{ props.rowData.key.mobile_desktop }}</Tag
                    >
                    <Tag
                      v-if="props.rowData.key.mobile_desktop == 'Mobile'"
                      color="blue"
                      >{{ props.rowData.key.mobile_desktop }}</Tag
                    >
                  </div>
                </div>
              </div>
            </td>
            <td>{{ props.rowData.key.country_code }}</td>
            <td v-if="filters.redirect?.value !== null">
              <template v-if="props.rowData.redirect">
                <router-link
                  :to="{
                    name: 'Redirect',
                    params: { id: props.rowData.redirect.id }
                  }"
                  class="link-wrap"
                >
                  <span class="font-semibold">
                    {{ props.rowData.redirect.id }}</span
                  >
                  ({{ props.rowData.redirect.subdomain }})
                </router-link>
              </template>
            </td>
  
            <td v-if="filters.redirect?.value !== null">
              <template v-if="props.rowData.campaign">
                <router-link
                  :to="{
                    name: 'Campaign',
                    params: { id: props.rowData.campaign.id }
                  }"
                  class="link-wrap"
                >
                  {{ props.rowData.campaign.label }}
                </router-link>
              </template>
            </td>
            <td>
              <div v-if="props.rowData.data?.rpm_average && props.rowData.data?.rpm_average !== 0">
                ${{ props.rowData.data.rpm_average }}
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <div v-if="props.rowData.data?.revenue && props.rowData.data?.revenue !== 0">
                ${{ props.rowData.data.revenue }}
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <div v-if="props.rowData.data.weight">
                {{ props.rowData.data.weight }}
              </div>
            </td>
            <td>
              <div v-if="props.rowData.data.min">
                {{ props.rowData.data.min }}
              </div>
            </td>
            <td>
              <div v-if="props.rowData.data.max">
                {{ props.rowData.data.max }}
              </div>
            </td>
            <td>
              <div v-if="props.rowData.data.expiry">
                {{ props.rowData.data.expiry }}
              </div>
            </td>
            <td>
              <div v-if="props.rowData.data.fallback">
                {{ props.rowData.data.fallback }}
              </div>
            </td>
          </template>
        </YTable>
      </div>
      <div class="mt-2">
    <YButton
          v-if="algorithmsDataV11.length > 0"
          class="block m-auto"
          color="link"
          @click="loadMore"
          :is-loading="$apollo.queries.algorithmsDataV11.loading"
          >LOAD MORE</YButton
        >
      </div>
    </div>
  </template>
  
  <script>
  import ALGORITHMS_DATA_QUERY from '@/graphql/AlgorithmsData/AlgorithmsDataQuery.gql'
  import QUALIFIED_REDIRECTS_QUERY from '@/graphql/Algorithm/AlgorithmQualifiedRedirectsQuery.gql'
  import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
  import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
  import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
  import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
  import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
  //import AlgorithmsDataEdit from '@/views/Admin/Algorithms/AlgorithmTwo/AlgorithmsDataEdit.vue'
  //import AlgorithmsDataDelete from '@/views/Admin/Algorithms/AlgorithmTwo/AlgorithmsDataDelete.vue'
  import AlgorithmOptimize from '@/views/Admin/Algorithms/AlgorithmEleven/AlgorithmOptimize.vue'
  import UPDATE_ALGORITHM_MUTATION from '@/graphql/Algorithm/UpdateAlgorithmMutation.gql'
  import gql from 'graphql-tag'
  
  export default {
    components: {
    },
    apollo: {
      algorithms: {
        query: gql`
          {
            algorithms {
              id
              metaData
              description
            }
          }
        `
      },
      qualifiedRedirectsV11:{
        query: QUALIFIED_REDIRECTS_QUERY,
        update: data => data.algorithmQualifiedRedirects,
        variables() {
          return {
            first: 100,
            filters: {
              countryCode: this.filters.country.value ? {"value": [this.filters.country.value.code],"modifiers": {"matchType": "contains"}} : undefined,
              algorithmId: {"value": [11],"modifiers": {"matchType": "equals"}},
            },
            orderBy: [{"column": "revenue","order": "DESC"}]
          }
        },
        fetchPolicy: 'network-only',
        deep: true
      },
      algorithmsDataV11: {
        query: ALGORITHMS_DATA_QUERY,
        update: data => data.algorithmsData,
        variables() {
          return {
            filters: {
              verticals: {
                value: this.filters.verticals.value
                  ? this.filters.verticals.value.map(vertical => vertical)
                  : null
              },
              countryCode: this.cleanObjectFilterValue(
                this.filters.country,
                'code'
              ),
              level: this.filters.level ? this.filters.level : undefined,
              offerId: this.cleanObjectFilterValue(this.filters.offer, 'id'),
              redirectId: this.cleanObjectFilterValue(
                this.filters.redirect,
                'id'
              ),
              campaignId: this.cleanObjectFilterValue(
                this.filters.campaign,
                'id'
              ),
              mobileDesktop: this.filters.device
                ? this.filters.device
                : undefined,
              paginate: this.perPage,
              algoId: 11
            }
          }
        },
        result: function(result) {
          if (result.data && result.data.algorithmsData) {
            this.lastUpdatedAt = result.data.algorithmsData[0].updatedAt
          } else {
            this.$set(this, 'algorithmsDataV11', [])
          }
        },
        fetchPolicy: 'network-only',
        deep: true
      },
      countries: {
        query: COUNTRIES_QUERY
      },
      tags: {
        query: TAGS_QUERY,
        update: data => data.tags,
        variables() {
          return {
            first: 25,
            page: 1,
            filters: {
              type: {
                value: ['vertical']
              }
            }
          }
        },
        result: function(result) {
          this.verticalOptions = result.data.tags.data.map(tag => tag.name)
        }
      }
    },
    updated() {},
    props: {},
    data() {
      return {
        qualifiedRedirectsV11:null,
        perPage: 50,
        loadMoreIncrement: 25,
        verticalOptions: [],
        showButtonText: 'Show Qualifed',
        showQualifiedRedirects: false,
        lastUpdatedAt: null,
        filters: {
          device: 'Mobile',
          level: 'General',
          verticals: {
            value: null
          },
          country: {
            value: {
              code: 'US',
              label: 'US - United States'
            },
            title: 'Country',
            type: 'select',
            multiple: true,
            optionLabelKey: 'label',
            trackByKey: 'code'
          },
          redirect: {
            value: null,
            title: 'Redirect',
            type: 'select',
            multiple: false,
            optionLabelKey: 'label',
            trackByKey: 'id'
          },
          campaign: {
            value: null,
            title: 'Campaign',
            type: 'select',
            multiple: false,
            optionLabelKey: 'label',
            trackByKey: 'id'
          },
          offer: {
            value: null,
            title: 'Offer',
            type: 'select',
            multiple: false,
            optionLabelKey: 'label',
            trackByKey: 'id'
          }
        },
        options: {
          offer: [],
          devices: ['Mobile', 'Desktop'],
          level: ['General', 'All']
        },
        queries: {
          OFFER_OPTIONS_QUERY,
          REDIRECT_OPTIONS_QUERY,
          CAMPAIGN_OPTIONS_QUERY
        },
        mutations:{
          UPDATE_ALGORITHM_MUTATION
        }
      }
    },
    computed: {
      algorithmDescription() {
        if (
          !this.algorithms ||
          !this.algorithms[10] ||
          !this.algorithms[10].description
        ) {
          return null
        }
        return this.algorithms[10].description
      },
    },
    methods: {
      loadMore() {
        this.perPage = this.perPage + this.loadMoreIncrement
      },
      toggleQualifiedRedirects(){
        if (this.showQualifiedRedirects == true) {
          this.showQualifiedRedirects = false
          this.showButtonText =  'Show Qualifed'
        } else {
          this.showQualifiedRedirects = true
          this.showButtonText =  'Hide Qualifed'
        }
      },
      openOptimizeModal() {
        this.$modal.show(
          AlgorithmOptimize,
          {
            filters: {
              mobileDesktop: this.filters.device
                ? this.filters.device
                : undefined,
              countryCode: this.cleanObjectFilterValue(
                this.filters.country,
                'code'
              ),
              redirectId: this.cleanObjectFilterValue(this.filters.redirect, 'id'),
              campaignId: this.cleanObjectFilterValue(this.filters.campaign, 'id'),
            },
            level: this.filters.level ? this.filters.level : undefined
          },
          {
            height: 'auto',
            width: '80%',
            maxWidth: 1200,
            adaptive: true,
            scrollable: true
          }
        )
      },
      cleanFilter(filter) {
        // Return undefined on null values
        // And make sure value is always an array
        return filter.value
          ? {
              value: Array.isArray(filter.value) ? filter.value : [filter.value],
              modifiers: filter.modifiers
            }
          : undefined
      },
      cleanObjectFilter(filter, pluck) {
        // defer to cleanFilter for undefined check and array cast
        if (this.cleanFilter(filter) === undefined) {
          return undefined
        }
  
        // pluck key from value
        return {
          value: this.cleanFilter(filter).value.map(value => value[pluck]),
          modifiers: filter.modifiers
        }
      },
      cleanObjectFilterValue(filter, pluck) {
        // defer to cleanFilter for undefined check and array cast
        if (this.cleanFilter(filter) === undefined) {
          return undefined
        }
  
        // pluck key from value
        return this.cleanFilter(filter).value.map(value => value[pluck])[0]
      },
      redirectDropdownChange(){
        if (this.filters.redirect?.value !== null){        
          if (this.filters.redirect.value.secondaryId && this.filters.redirect.value.secondaryLabel){
            this.filters.campaign.value = [
              {
                 "id": this.filters.redirect.value.secondaryId,
                 "label": this.filters.redirect.value.secondaryLabel
              }
            ]
            this.filters.level = 'Redirect'
          }
        } else {
          this.filters.campaign.value = null
          this.filters.level = 'General'
        }
      },
      updateFilters(redirect,campaign){
        this.filters.level = 'All'
        this.filters.redirect.value = [
            {
                "id": redirect.id,
                "label": redirect.label
            }
        ]
        this.filters.campaign.value = [
              {
                  "id": campaign.id,
                  "label": campaign.label
              }
            ]
      }
      
    },
    mounted() {
      this.$events.listen('offerTrafficked', () => {
        this.$apollo.queries.algorithmsDataV11.refetch()
      })
      this.$events.listen('refreshAlgorithmsData', () => {
        this.$apollo.queries.algorithmsDataV11.refetch()
      })
      this.$tabEvent.on('refreshAlgorithmsData', () => {
        this.$apollo.queries.algorithmsDataV11.refetch()
      })
    },
    beforeDestroy() {
      this.$events.remove('refreshAlgorithmsData')
      this.$events.remove('offerTrafficked')
      this.$tabEvent.off('refreshAlgorithmsData')
    }
  }
  </script>
  