<template>
  <div v-if="paymentSummary">
    <widget class="mb-4 p-1 text-base bg-gray-100">
      <widget-body>
        <div class="grid grid-cols-2 gap-3">
          <div>
            <span class="font-bold mr-2"> Payment Frequency: </span>
            {{ frequency }}
          </div>
          <div>
            <span class="font-bold mr-2" v-if="paymentMethod">
              Payment Method:</span
            >
            {{ paymentMethod }}
          </div>
          <div>
            <span class="font-bold mr-2"> Payment Terms: </span>
            {{ paymentTerms }}
          </div>
          <div>
            <span class="font-bold mr-2" v-if="threshold">
              Payment Threshold:
            </span>
            {{ threshold }}
          </div>
          <div v-if="this.paymentSummary.paymentBalance">
            <span class="font-bold mr-2"> Outstanding Balance: </span>
            <span
              >{{
                Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(this.paymentSummary.paymentBalance)
              }}
              (<span class="text-xs italic"
                >as of {{ this.paymentSummary.confirmedAsDate }}</span
              >)</span
            >
          </div>
          <div v-else>
            <span class="font-bold mr-2"> Outstanding Balance: </span>$0
          </div>
          <div>
            <span class="font-bold mr-2"> Next Payment Date: </span>
            {{
              paymentMethod == 'No Payment Method' ||
              paymentMethod == 'Hold My Payments'
                ? paymentMethod
                : this.paymentSummary.nextDate ?? 'N/A'
            }}
          </div>
        </div>
        <div class="w-full text-left pt-1">
          <span class="text-xs italic">
            Please allow 2-3 business days for changes to payment information to
            be updated in your account.</span
          >
        </div>
      </widget-body>
    </widget>

    <div class="grid grid-cols-1 lg:grid-cols-7 2xl:grid-cols-5 gap-6">
      <div class="col-span-5 2xl:col-span-3 flex gap-6">
        <Tabs show-type="show" :route="tabRoute">
          <Tab name="Payment Info" :active="true">
            <PublisherPaymentDetails :user="me"> </PublisherPaymentDetails>
          </Tab>

          <Tab name="Payment History">
            <PublisherPaymentHistory :user="me"></PublisherPaymentHistory
          ></Tab>
        </Tabs>
      </div>

      <div class="col-span-2 2xl:col-span-2 flex flex-col gap-6 pt-8">
        <div
          v-if="canadianResident"
          class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
        >
          <p class="uppercase text-xs font-semibold text-gray-800 mb-2">
            canadian publishers only
            <span class="text-red-600">*</span>
          </p>
          <form v-on:submit.prevent="update" class="w-full">
            <div class="flex flex-col gap-1">
              <Superselect
                title="Tax Type"
                v-model="localUser.billingContact.taxType"
                :multiple="false"
                :options="taxTypeOptions"
                :required="true"
              ></Superselect>
              <YInput
                label="Tax Number"
                v-model="localUser.billingContact.taxNumber"
                :required="true"
              />
              <YButton color="blue" class="mt-1 self-end w-32">Update</YButton>
            </div>
          </form>
        </div>

        <div
          class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
        >
          <div
            class="uppercase text-xs font-semibold text-gray-800 flex justify-between items-center"
          >
            Payment Methods & Thresholds
            <Icon name="information" size="6" class="text-teal-600" />
          </div>
          <div class="text-xs text-teal-600">
            Payments process upon reaching the minimum thresholds for your
            chosen method. For payment inquiries, confirm your selected option.
          </div>
          <table class="w-full bg-gray-100 mt-4">
            <thead>
              <tr class="text-gray-800">
                <th>Payment Method</th>
                <th>Threshold (USD)</th>
                <th class="hidden 2xl:block">Currency Options</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(payment, index) in paymentMethodsTableValues"
                :key="index"
              >
                <td>
                  <div class="flex flex-col">
                    <p>{{ payment.name }}</p>
                    <p class="text-xs">{{ payment.locationAvailable }}</p>
                    <p class="text-xs text-gray-800 2xl:hidden">
                      Currency Option: {{ payment.currencyOption }}
                    </p>
                  </div>
                </td>
                <td>{{ payment.threshold }}</td>
                <td class="hidden 2xl:table-cell">
                  {{ payment.currencyOption }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import PublisherPaymentDetails from '@/views/Publisher/Account/PublisherPaymentForm'
import PublisherPaymentHistory from '@/views/Publisher/Account/PublisherPaymentHistory'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'

export default {
  components: {
    PublisherPaymentDetails,
    PublisherPaymentHistory
  },
  props: {
    tab: {
      type: String,
      default: 'payment-info'
    }
  },
  apollo: {
    me: {
      query: ME_QUERY,
      result(data) {
        this.paymentSummary = data.data.me.billingContact ?? null
        if (this.paymentSummary) {
          this.paymentSummary.nextDate = this.paymentSummary.paymentMessage

          if (this.paymentSummary.confirmedAsDate != null) {
            this.paymentSummary.confirmedAsDate = this.paymentSummary.confirmedAsDate.split(
              ' '
            )[0]
          }
          if (
            this.paymentSummary.paymentMessage == null &&
            this.paymentSummary.nextPaymentDate != null
          ) {
            this.paymentSummary.nextDate = this.paymentSummary.nextPaymentDate.split(
              ' '
            )[0]
          }
          if (this.paymentSummary.paymentBalance) {
            if (
              this.paymentSummary?.paymentBalance.split('.')[1]?.length == 1
            ) {
              this.paymentSummary.paymentBalance =
                this.paymentSummary.paymentBalance + '0'
            }
          }
        }
        this.localUser = data.data.me
        this.paymentThreshold(this.paymentSummary)
        this.canadianResident = data.data.me.primaryContact.country.code == 'CA'
      }
    },
    taxTypeOptions: {
      query: gql`
        {
          taxTypeOptions: __type(name: "CanadianTaxTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.taxTypeOptions = data.taxTypeOptions.enumValues.map(
            option => option.name
          )
        }
      }
    }
  },
  data() {
    return {
      isUpdating: false,
      activeTab: this.tab,
      paymentSummary: null,
      canadianResident: false,
      taxTypeOptions: [],
      localUser: null,
      threshold: '',
      paymentBalance: '',
      nextPaymentDate: '',
      paymentMessage: '',
      balanceUploadDate: '',
      paymentMethod: '',
      paymentThresholds: [
        {
          type: 'WireTransfer',
          name: 'Wire Transfer',
          locationAvailable: 'Domestic or International',
          threshold: '$1,000.00',
          thresholdCurrency: 'USD',
          currencyOption: 'USD or Local'
        },
        {
          type: 'PayPal',
          name: 'PayPal',
          threshold: '$50.00',
          thresholdCurrency: 'USD',
          currencyOption: 'USD only'
        },
        {
          type: 'eCheck',
          name: 'eCheck',
          locationAvailable: 'North America/Europe Only',
          threshold: '$500.00',
          thresholdCurrency: 'USD',
          currencyOption: 'Local Currency'
        },
        {
          type: 'ACH',
          name: 'ACH',
          locationAvailable: 'US Only',
          threshold: '$50.00',
          thresholdCurrency: 'USD',
          currencyOption: 'USD Only'
        },
        {
          type: 'Check',
          name: 'Check',
          threshold: '-',
          thresholdCurrency: 'USD'
        },
        { type: 'NoPM', name: 'No Payment Method', threshold: '-' },
        { type: 'HoldMyPayments', name: 'Hold My Payments', threshold: '-' }
      ]
    }
  },

  methods: {
    paymentThreshold(value) {
      if (value.paymentMethod) {
        let filtered = this.paymentThresholds.find(payment => {
          return payment.type == value.paymentMethod
        })
        this.paymentMethod = filtered.name
        this.threshold =
          filtered.threshold == '-'
            ? filtered.threshold
            : filtered.thresholdCurrency + ' ' + filtered.threshold
      }
    },
    update() {
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,

          variables: {
            input: {
              id: this.localUser.id,
              contacts: {
                update: [
                  {
                    id: this.localUser.billingContact.id,
                    taxType: this.localUser.billingContact.taxType,
                    taxNumber: this.localUser.billingContact.taxNumber
                  }
                ]
              }
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)
        })
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Publisher Finance', params: { tab: this.tab } }
    },
    frequency() {
      return this.paymentSummary
        ? this.paymentSummary.paymentFrequency.split(/(?=[A-Z])/).join(' ')
        : null
    },
    paymentTerms() {
      return this.paymentSummary
        ? this.paymentSummary.paymentTerms
            .replace('NET', 'Net')
            .split(/(\d+\.|\d+)+/g)
            .join(' ')
        : null
    },
    paymentMethodsTableValues() {
      return this.paymentThresholds.filter(payment => payment.threshold !== '-')
    }
  },
  mounted() {},
  updated() {}
}
</script>

<style scoped>
td {
  padding: 5px;
  border-bottom: 1px solid darkgray;
}
</style>
