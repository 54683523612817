var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex gap-2"
  }, _vm._l(_vm.availableTypes, function (imageType) {
    return _c('div', {
      key: imageType,
      staticClass: "flex gap-2"
    }, [_c('div', [_c('div', {
      staticClass: "p-1 w-24 h-24 border-2 bg-gray-200 border-gray-100 rounded-md hover:border-blue-200",
      on: {
        "click": function () {
          _vm.openImageLibrary(imageType);
        }
      }
    }, [_vm.getImage(imageType) ? _c('img', {
      key: imageType,
      staticClass: "w-full h-full",
      attrs: {
        "src": _vm.getUrl(_vm.getImage(imageType), 'thumb'),
        "height": "120",
        "width": "120"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "text-center"
    }, [_c('span', {
      staticClass: "uppercase text-xxs text-gray-500"
    }, [_vm._v(_vm._s(imageType))])])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }