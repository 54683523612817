<template>
<div class="flex flex-col min-h-full justify-between bg-white p-2 rounded-md image-library">
  <div class="search-bar">
    <label aria-hidden for="query" class="hidden">Search</label>
    <div class="mt-2 flex">
      <div class="w-full -mr-px grid flex-grow grid-cols-1 focus-within:relative">
        <input
          v-model="search"
          type="text"
          name="query"
          id="query"
          class="rounded-none col-start-1 row-start-1 block w-full bg-white py-2 pl-10 pr-3 text-base text-gray-900 border-b -mt-2 border-gray-300 placeholder-gray-400 focus:border-indigo-400 sm:pl-10 focus:outline-none"
          placeholder="Search"
        />
        <Icon class="pointer-events-none col-start-1 row-start-1 ml-3 w-5 h-5 self-center text-gray-400" name="magnify" size="4" />
      </div>
    </div>
  </div>
  <div :class="[
        'flex-grow flex flex-wrap gap-1 my-4 justify-center min-h-64 relative',
        isLoading ? 'opacity-50 pointer-events-none cursor-not-allowed' : '',
    ]">
    <div v-if="isLoading" class="absolute h-full w-full flex items-center justify-center z-10">
        <Spinner color="blue" :size="12" />
    </div>
    <div 
        v-for="image in images?.data" :key="image.id"
        :class="[
            'relative p-1 w-20 h-20 rounded-md border-2 border-gray-100 overflow-hidden',
            selected && selected.id == image.id ? 'border-blue-500' : 'hover:border-blue-200',
            isLoading ? 'opacity-25' : '',
        ]"
        @click="()=>{selected = image}"
    >
        <div class="w-1/2 bg-gray-200 h-full absolute z-0 left-0 top-0"></div>
        <div class="w-1/2 bg-gray-200 h-full absolute z-0 right-0 top-0"></div>
        <img 
            class="relative z-1"
            v-tooltip="image.name"
            :src="image.imageThumbnailUrl" 
        />
    </div>
  </div>
  <div class="flex justify-between">
    <div>
        <YButton class="btn" @click="$emit('close')">Cancel</YButton>
    </div>
    <div>
        <YButton class="btn btn-blue" @click="()=>{onSubmit(selected); $emit('close')}">{{ submitText }}</YButton>
    </div>
  </div>
</div>
</template>
<script>
    import IMAGES_QUERY from '@/graphql/ImageRepository/ImagesQuery.gql'

    export default {
        components: {
      
        },
        props: {
            submitText: {
                type: String,
                default: 'Select'
            },
            onSubmit: {
                type: Function,
                default: ()=>{}
            }
        },
        data() {
            return {
                IMAGES_QUERY,
                selected: null,
                search: '',
                isLoading: false,
            }
        },
        computed: {
            // images(){
            //     return this.$apollo.queries.images.data.images
            // }
        },
        apollo: {
            images: {
                query: IMAGES_QUERY,
                debounce: 250,
                variables() {
                    return {
                        first: 21,
                        filters: {
                            name: {
                                value: [this.search],
                                modifiers: { matchType: 'contains' },
                            }
                        }
                    }
                },
                fetchPolicy: 'cache-and-network',
                result() {
                    this.isLoading = false
                },
                deep: false
            },
        },
        watch: {
            search() {
                console.log('changed')
                this.isLoading = true; // Example of handling change
            }
        },
        methods:{
            getImage(type){
                return this.images.find(image=> image.type === type)
            },
            getConversion(image, conversion = 'thumb'){
                return image.conversions.find(item=> item.conversion === conversion)
            },
            getUrl(image, conversion = 'thumb'){
                return this.getConversion(image, conversion).storageUrl
            },
        }
    }
</script>
<style scoped>
    .image-library{
        /* margin: 2rem;
        width: 80rem;
        height: 40rem;
        max-width: 90%;
        max-height: 90%; */
    }
</style>