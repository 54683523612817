var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    attrs: {
      "loading": !_vm.optimizedOffersV11
    }
  }, [_c('widget-header', {
    staticClass: "background-gradient-green-blue text-white tiny-forms"
  }, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v("11 - Hourly (30 days, no reigon) Optmized Results for " + _vm._s(this.filters.countryCode)), _c('span'), _c('h4', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1), _c('br'), _c('br'), _c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Bucket Rpm: $" + _vm._s(this.bucketRpmV11) + " ")]), _c('widget-body', [_vm.optimizedOffersV11 ? _c('YTable', {
    attrs: {
      "data": _vm.optimizedOffersV11 || [],
      "bottom-loading": this.isSubmitting == true
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "370px"
          }
        }, [props.rowData.algoOffer ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                id: props.rowData.algoOffer.id,
                action: 'payouts'
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.rowData.algoOffer.label) + " ")])] : _vm._e()], 2), _c('td', [props.rowData.device == 'DESKTOP' ? _c('Tag', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v("Desktop")]) : _vm._e(), props.rowData.device == 'MOBILE' ? _c('Tag', {
          attrs: {
            "color": "purple"
          }
        }, [_vm._v("Mobile")]) : _vm._e()], 1), props.rowData.redirect ? _c('td', [[_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.id) + " - " + _vm._s(props.rowData.redirect.subdomain) + "." + _vm._s(props.rowData.redirect.domain) + " ")])]], 2) : _vm._e(), _c('td', [_vm._v(" " + _vm._s(props.rowData.data && props.rowData.data.rpm_average ? _vm.$formatMoney(props.rowData.data.rpm_average) : _vm.$formatMoney(0)) + " ")]), _c('td', [props.rowData.data && props.rowData.data.previous_weight ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.previous_weight) + " ")]) : _vm._e()]), _c('td', [props.rowData.data && props.rowData.data.weight ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.weight) + " ")]) : _vm._e()])];
      }
    }], null, false, 1513490569)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Offer")]), _c('th', [_vm._v("Device")]), this.filters.redirectId ? _c('th', [_vm._v("Redirect")]) : _vm._e(), _c('th', [_vm._v("Rpm Average")]), _c('th', [_vm._v("Previous")]), _c('th', [_vm._v("New")])])], 2) : _vm._e()], 1), _c('widget-footer', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }