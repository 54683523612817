var render = function render(){
  var _vm$images;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col min-h-full justify-between bg-white p-2 rounded-md image-library"
  }, [_c('div', {
    staticClass: "search-bar"
  }, [_c('label', {
    staticClass: "hidden",
    attrs: {
      "aria-hidden": "",
      "for": "query"
    }
  }, [_vm._v("Search")]), _c('div', {
    staticClass: "mt-2 flex"
  }, [_c('div', {
    staticClass: "w-full -mr-px grid flex-grow grid-cols-1 focus-within:relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "rounded-none col-start-1 row-start-1 block w-full bg-white py-2 pl-10 pr-3 text-base text-gray-900 border-b -mt-2 border-gray-300 placeholder-gray-400 focus:border-indigo-400 sm:pl-10 focus:outline-none",
    attrs: {
      "type": "text",
      "name": "query",
      "id": "query",
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), _c('Icon', {
    staticClass: "pointer-events-none col-start-1 row-start-1 ml-3 w-5 h-5 self-center text-gray-400",
    attrs: {
      "name": "magnify",
      "size": "4"
    }
  })], 1)])]), _c('div', {
    class: ['flex-grow flex flex-wrap gap-1 my-4 justify-center min-h-64 relative', _vm.isLoading ? 'opacity-50 pointer-events-none cursor-not-allowed' : '']
  }, [_vm.isLoading ? _c('div', {
    staticClass: "absolute h-full w-full flex items-center justify-center z-10"
  }, [_c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 12
    }
  })], 1) : _vm._e(), _vm._l((_vm$images = _vm.images) === null || _vm$images === void 0 ? void 0 : _vm$images.data, function (image) {
    return _c('div', {
      key: image.id,
      class: ['relative p-1 w-20 h-20 rounded-md border-2 border-gray-100 overflow-hidden', _vm.selected && _vm.selected.id == image.id ? 'border-blue-500' : 'hover:border-blue-200', _vm.isLoading ? 'opacity-25' : ''],
      on: {
        "click": function () {
          _vm.selected = image;
        }
      }
    }, [_c('div', {
      staticClass: "w-1/2 bg-gray-200 h-full absolute z-0 left-0 top-0"
    }), _c('div', {
      staticClass: "w-1/2 bg-gray-200 h-full absolute z-0 right-0 top-0"
    }), _c('img', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: image.name,
        expression: "image.name"
      }],
      staticClass: "relative z-1",
      attrs: {
        "src": image.imageThumbnailUrl
      }
    })]);
  })], 2), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', [_c('YButton', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('div', [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": function () {
        _vm.onSubmit(_vm.selected);
        _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.submitText))])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }