<template>
    <div class="flex gap-2">
        <div v-for="imageType in availableTypes" class="flex gap-2" :key="imageType">
            <div>
                <div @click="()=>{openImageLibrary(imageType)}"  class="p-1 w-24 h-24 border-2 bg-gray-200 border-gray-100 rounded-md hover:border-blue-200">
                    <img
                        v-if="getImage(imageType)"
                        :key="imageType"
                        :src="getUrl(getImage(imageType), 'thumb')"
                        class="w-full h-full"
                        height="120"
                        width="120"
                    />
                </div>
                <div class="text-center">
                    <span class="uppercase text-xxs text-gray-500">{{ imageType }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import EditableImage from '@/components/utilities/EditableImage'
    import ImageLibrary from '@/components/general/ImageLibrary'
    import IMAGES_QUERY from '@/graphql/ImageRepository/ImagesQuery.gql'
    import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'


    export default {
        components: {
            // EditableImage,        
        },
        props: {
            images: {
                type: Array,
                required: true,
            },
            availableTypes: {
                type: Array,
                default: () => ['template', 'logo', 'logo-dark'],
            }
        },
        data() {
            return {
                UPDATE_CAMPAIGN_MUTATION,
                IMAGES_QUERY,
            }
        },
        methods:{
            onSubmit(value, imageType){
                this.$emit('submitted', {image: value, type: imageType})
            },
            openImageLibrary(imageType){
                console.log('opening', imageType)
                this.$modal.show(
                    ImageLibrary,
                    {
                        onSubmit: (value)=>{this.onSubmit(value, imageType)},
                        submitText: 'Save',
                    },
                    {

                    },
                    {

                    }
                )
            },
            getImage(type){
                return this.images.find(image=> image.type === type)
            },
            getConversion(image, conversion = 'thumb'){
                return image.conversions.find(item=> item.conversion === conversion)
            },
            getUrl(image, conversion = 'thumb'){
                return this.getConversion(image, conversion).storageUrl
            },
        }
    }
</script>