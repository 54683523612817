import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { uuidv4 } from './assets/js/uuid'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory'
import introspectionQueryResultData from './generated/fragmentTypes.json'
import gitVersion from './generated/gitInfo.js'

import Pusher from 'pusher-js'
import PusherLink from './pusher-link'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})
export const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'ImageData':
        return object.__typename + ':' + object.id + ':' + object.type
      case 'Template':
        return object.__typename + ':' + object.path
      case 'PublisherCampaign':
        return object.__typename + ':' + object.id + ':' + object.publisherId
      case 'Country':
        return object.__typename + ':' + object.code
      case 'Tag':
        if (object.pivot) {
          return (
            object.__typename +
            ':' +
            object.pivot.tagId +
            ':' +
            object.pivot.taggableId +
            ':' +
            object.pivot.taggableType
          )
        }
        return object.__typename + ':' + object.id
    }
    if (object.__typename && object.id) {
      return object.__typename + ':' + object.id
    }
    if (object.__typename && object._id) {
      return object.__typename + ':' + object._id
    }
    return
  }
})

import {
  createApolloClient,
  restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client'

// This is a custom version of createApolloClient imported from "vue-cli-plugin-apollo/graphql-client"
// It is identical except it uses client version 3.
// When vue apollo supports v3, delete this custom package
// import {
//   createApolloClient,
//   restartWebsockets
// } from "@/customCreateApolloClient";

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || '/graphql/'

const PUSHER_API_KEY = process.env.VUE_APP_PUSHER_API_KEY
const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER
const API_LOCATION = process.env.VUE_APP_GRAPHQL_API_SERVER
const BEARER_TOKEN = localStorage.getItem(AUTH_TOKEN)

window.pusherLink = new PusherLink({
  pusher: new Pusher(PUSHER_API_KEY, {
    cluster: PUSHER_CLUSTER,
    authEndpoint: `${API_LOCATION}/graphql/subscriptions/auth`,
    auth: {
      headers: {
        authorization: BEARER_TOKEN
      }
    }
  })
})

const APP_INSTANCE_ID = uuidv4()
Vue.prototype.$appInstance = APP_INSTANCE_ID

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'ytrack-app-instance': APP_INSTANCE_ID
    }
  }
})

const link = new ApolloLink.from([
  window.pusherLink,
  authLink,
  createUploadLink({ uri: httpEndpoint })
])

window.pusherLink.pusher.disconnect()

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  //wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql",
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  cache: cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  apollo: {
    link: link,
    name: 'ytrack-fe',
    version: gitVersion
  }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-first'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
