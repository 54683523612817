<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template>Edit {{ domain.id }}</template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <Superselect
        title="Status"
        v-model="status"
        :multiple="false"
        placeholder="Select"
        :allow-empty="false"
        :query="INTROSPECTION_TYPE_QUERY"
        :query-variables="{ name: 'DomainStatusEnum' }"
        :query-result-function="
          data => {
            return data.__type.enumValues.map(value => value.name)
          }
        "
      ></Superselect>
      <Superselect
        v-if="status == 'flagged' && readyDomains.length > 0"
        class="mt-2"
        title="Replacement Domain"
        v-model="replacementDomain"
        :options="readyDomains"
        placeholder="Select"
        :multiple="false"
        track-by="id"
        label="label"
      ></Superselect>
      <div
        v-if="status == 'flagged' && readyDomains.length <= 0"
        class="pt-1 text-center"
      >
        <small class="text-muted" style="font-style: italic;"
          >No Domains Available</small
        >
      </div>
      <Superselect
        class="mt-2"
        title="Domain Type"
        v-model="domainType"
        :options="typeOptions"
        placeholder="Select"
        :multiple="false"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateDomain"
        :disabled="!canSubmit"
        >Update</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import INTROSPECTION_TYPE_QUERY from '@/graphql/Introspection/IntrospectionTypeQuery.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import UPDATE_DOMAIN_STATUS_MUTATION from '@/graphql/Domain/UpdateDomainStatusMutation.gql'

export default {
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  mixins: [ValidationErrorsMixin],
  apollo: {
    ydnsDomains: {
      query: gql`
        query ydnsDomains(
          $filters: YdnsDomainFilterInput
          $recordFilters: YdnsRecordFilterInput
        ) {
          ydnsDomains(filters: $filters) {
            id
            registrar
            type
            records(filters: $recordFilters) {
              id
              type
              subdomain
              ip {
                id
                server {
                  id
                }
              }
              content
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: { value: [this.domain.type, null] },
            status: { value: ['ready', null] },
            hasRecords: this.domain.type == 'mini' ? false : undefined
          }
        }
      },
      result({ data }) {
        this.readyDomains = data.ydnsDomains.map(domain => ({
          id: domain.id,
          label: `${domain.id} - ${domain.registrar}`
        }))
      }
    }
  },
  components: {},
  data() {
    return {
      INTROSPECTION_TYPE_QUERY,
      DOMAIN_OPTIONS_QUERY,
      readyDomains: [],
      typeOptions: ['ytrackMini', 'ytrackRedirect','allInMini', 'allInRedirect', 'nameserver', 'offer'],
      statusOptions: ['ready', 'active', 'flagged'],
      replacementDomain: null,
      domainType: this.domain.type,
      status: this.domain.status,
      closeOnComplete: true,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {
    canSubmit() {
      if (!this.status) {
        return false
      }
      return true
    }
  },
  mounted() {},
  methods: {
    updateDomain() {
      this.clearValidationErrors()
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_DOMAIN_STATUS_MUTATION,
          variables: {
            input: [
              {
                domain: this.domain.id,
                status: this.status,
                type: this.domainType ?? undefined,
                replacementDomain:
                  this.status == 'flagged' && this.replacementDomain
                    ? this.replacementDomain.id
                    : undefined
              }
            ]
          }
        })
        .then(result => {
          this.$toastr.s('Domain Status Updated', 'Success!')
          this.clearValidationErrors()
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshYdnsDomains')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
