<template>
    <widget :loading="!optimizedOffersV11">
      <widget-header class="background-gradient-green-blue text-white tiny-forms">
        <i class="zmdi zmdi-file-plus pr-2"></i>11 - Hourly (30 days, no reigon)
        Optmized Results for {{ this.filters.countryCode }}<span></span>
        <h4 slot="right">
          <close-x @click="$emit('close')" class="text-white"></close-x>
        </h4>
        <br /><br /><i class="zmdi zmdi-file-plus pr-2"></i> Bucket Rpm: ${{
          this.bucketRpmV11
        }}
      </widget-header>
      <widget-body>
        <YTable
          v-if="optimizedOffersV11"
          :data="optimizedOffersV11 || []"
          :bottom-loading="this.isSubmitting == true"
        >
          <template slot="header-row">
            <th>Offer</th>
            <th>Device</th>
            <th v-if="this.filters.redirectId">Redirect</th>
            <th >Rpm Average</th>
            <th>Previous</th>
            <th>New</th>
          </template>
          <template slot="row" slot-scope="props">
            <td style="max-width: 370px;" class="truncate">
              <template v-if="props.rowData.algoOffer">
                <router-link
                  :to="{
                    name: 'Offer',
                    params: { id: props.rowData.algoOffer.id, action: 'payouts' }
                  }"
                  target="_blank"
                  class="link"
                >
                  {{ props.rowData.algoOffer.label }}
                </router-link>
              </template>
            </td>
  
            <td>
              <Tag
                v-if="props.rowData.device == 'DESKTOP'"
                color="green"
                >Desktop</Tag
              >
              <Tag
                v-if="props.rowData.device == 'MOBILE'"
                color="purple"
                >Mobile</Tag
              >
            </td>
            <td v-if="props.rowData.redirect">
              <template>
                <router-link
                  :to="{
                    name: 'Redirect',
                    params: { id: props.rowData.redirect.id }
                  }"
                  class="link"
                >
                  {{ props.rowData.redirect.id }} -
                  {{ props.rowData.redirect.subdomain }}.{{
                    props.rowData.redirect.domain
                  }}
                </router-link>
              </template>
            </td>
            <td>
              {{
                props.rowData.data && props.rowData.data.rpm_average
                  ? $formatMoney(props.rowData.data.rpm_average)
                  : $formatMoney(0)
              }}
            </td>
            <td>
              <div
                v-if="props.rowData.data && props.rowData.data.previous_weight"
              >
                {{ props.rowData.data.previous_weight }}
              </div>
            </td>
            <td>
              <div v-if="props.rowData.data && props.rowData.data.weight">
                {{ props.rowData.data.weight }}
              </div>
            </td>
          </template>
        </YTable>
      </widget-body>
      <widget-footer class="px-4 py-2 bg-gray-100 text-right"> </widget-footer>
    </widget>
  </template>
  
  <script>
  import gql from 'graphql-tag'
  import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
  
  export default {
    mixins: [ValidationErrorsMixin],
    apollo: {
      $subscribe: {
        offersOptimized: {
          query: gql`
            subscription OffersOptimizeSubscription {
              offersOptimizedV11
            }
          `,
          variables() {
            return {}
          },
          result(data) {
            this.getOptmizedResults(data.data.offersOptimizedV11)
          }
        }
      }
    },
    props: {
      filters: {
        type: Object,
        required: true
      }
    },
    components: {},
    data() {
      return {
        optimizedOffersV1: null,
        bucketRpmV11: null,
        isSubmitting: true
      }
    },
    watch: {},
    computed: {},
    close() {
      this.$emit('close')
    },
    mounted() {
      this.runOptimizations()
    },
    updated() {},
    methods: {
      getOptmizedResults(id) {
        this.$apollo
          .query({
            query: gql`
              query GetOptmizedResults($id: ID!) {
                getOptmizedResults(id: $id) {
                  id
                  key
                  device
                  redirect {
                    id
                    label
                    subdomain
                    domain
                  }
                  algoOffer {
                    id
                    label
                  }
                  data
                }
              }
            `,
            variables: {
              id: id
            }
          })
          .then(({ data }) => {
            this.optimizedOffersV11 = data.getOptmizedResults
            this.bucketRpmV11 = this.optimizedOffersV11[0].data.bucketRpm
            this.isSubmitting = false
            this.$events.emit('refreshAlgorithmsData', true)
            this.$events.emit('refreshAlgorithmsDataV11', true)
            this.$events.emit('refreshAlgorithmsDataV10', true)
          })
          .catch(error => {
            this.setValidationErrors(error)
            this.isSubmitting = true
          })
      },
      runOptimizations() {
        this.isSubmitting = true
        this.filters.algoId = 11
        this.$apollo
          .mutate({
            mutation: gql`
              mutation optimizeOffers($input: OptimizeOffersInput!) {
                optimizeOffers(input: $input) {
                  status
                  errors
                }
              }
            `,
            variables: {
              input: this.filters
            }
          })
          .then(() => {})
          .catch(() => {
            this.isSubmitting = false
          })
      }
    }
  }
  </script>
  <style scoped>
  .x-form > *:not(:last-child) {
    @apply mb-2;
  }
  </style>
  